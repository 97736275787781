export default {
  data: () => ({
    showing: false
  }),

  methods: {
    updateShowing(val) {
      this.showing = val;
    },

    toggle() {
      this.showing = !this.showing;
    }
  }
};
