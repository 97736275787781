<template lang="pug">
  component(:is="component" :to="to" @click="$emit('click')").sidebar__menu
    v-tooltip(right content-class="sidebar-tooltip")
      template(v-slot:activator="{on}")
        .sidebar-menu(
          v-on="on"
          @click="$emit('click')"
          @mouseover="$emit('mouseover', $event)"
          @mouseleave="$emit('mouseleave', $event)"
        )
          .sidebar-menu__wrapper
            slot(name="icon")
      span {{ tooltipText }}
    slot
</template>

<script>
export default {
  name: 'SidebarMenuItem',

  props: {
    tooltipText: String,
    component: {
      type: String,
      default: 'router-link'
    },
    to: {
      type: null,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.sidebar-tooltip {
  @include media("<=tablet") {
    display: none !important;
  }
}
</style>
