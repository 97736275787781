<template lang="pug">
  v-menu(offset-x v-model="showing").text-center
    template(v-slot:activator="{ on }")
      slot(name="activator" :on="on" :toggle="toggle")
        .sidebar__menu.sidebar__menu_last
          .sidebar-menu(v-on="on")
            .sidebar-menu__wrapper
              ico-settings.sidebar-menu__icon

    v-list.user-menu
      v-list-item(@click="logout").user-menu__item
        v-list-item-title Logout
        v-list-item-icon
          i.material-icons logout
</template>

<script>
import errMixin from "@/mixins/errors.mixin"
import SidebarMenu from "@/components/global/sidebar/SidebarMenu.vue"
import showingMixin from '@/mixins/showing.mixin'
import icoSettings from "@/assets/img/ui/crm/IcoSettings.vue"

export default {
  name: 'UserMenu',

  mixins: [errMixin, showingMixin],

  inject: ['logout'],

  components: {
    sidebarMenu: SidebarMenu,
    icoSettings
  }
}
</script>

<style lang="scss">
.user-menu {
  &__item {
    cursor: pointer;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $bg-active-color;
      content: "";
      opacity: 0;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      pointer-events: none;
    }

    &:hover:before {
      opacity: 0.04;
    }
  }
}

</style>
